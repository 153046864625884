import React from "react"
import Head from "./Head"

const PageTitle = ({ title }) => {
  return (
    <section className="section has-background-primary	">
      <Head pageTitle={title} />
      <div className="container">
        <div className="content has-text-centered">
          <h1 className="title has-text-white	">{title}</h1>
        </div>
      </div>
    </section>
  )
}

export default PageTitle
